@import "tree-chart.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.contextMenu {
    position: absolute;
    height: auto;
    z-index: 10;
}

.min-h-56 {
    min-height: 14rem;
}

.min-h-66 {
    min-height: 19rem;
}

.min-h-76 {
    min-height: 24rem;
}

.ql-container.ql-snow {
    max-height: 46rem;
    overflow: auto;
}

.mockup-code:before {
    display: none;
}
