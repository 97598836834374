.tree input[type="checkbox"] {
    display: none;
}

.tree {
    display: inline-block;
    transform: scale(1);
    transform-origin: top left;
}

.tree * {
    box-sizing: border-box;
}

.tree input ~ .branch {
    opacity: 0;
    height: 0;
    pointer-events: none;
    /* display: none; */
}

.tree input:checked ~ .branch {
    opacity: 1;
    height: auto;
    pointer-events: visible;
    /* display: block; */
}

.tree input ~ .branch .entry label {
    cursor: auto;
}

.tree > .branch > .entry label,
.tree input:checked ~ .branch .entry label {
    cursor: pointer;
}

.tree .branch {
    padding: 5px 0 5px 20px;
    transition-duration: 0.5s;
}

.tree .branch:not(:first-child) {
    margin-left: 220px;
}

.tree .branch:not(:first-child):after {
    content: "";
    width: 20px;
    border-top: 1px solid #ccc;
    position: absolute;
    left: 200px;
    top: 50%;
    margin-top: 1px;
}

.entry {
    position: relative;
    min-height: 1.8rem;
    display: block;
}

.entry:before {
    content: "";
    height: 100%;
    border-left: 1px solid #ccc;
    position: absolute;
    left: -20px;
}

.entry:first-child:after {
    height: 10px;
    border-radius: 10px 0 0 0;
}

.entry:first-child:before {
    width: 10px;
    height: 50%;
    top: 50%;
    margin-top: 1px;
    border-radius: 10px 0 0 0;
}

.entry:after {
    content: "";
    width: 20px;
    transition: border 0.5s;
    border-top: 1px solid #ccc;
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: 1px;
}

.entry:last-child:before {
    width: 10px;
    height: 50%;
    border-radius: 0 0 0 10px;
}
.entry:last-child:after {
    height: 10px;
    border-top: none;
    transition: border 0.5s;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 0 10px;
    margin-top: -9px;
}

.entry:only-child:after {
    width: 10px;
    height: 0px;
    margin-top: 1px;
    border-radius: 0px;
}

.entry:only-child:before {
    display: none;
}

.entry span,
.entry label {
    border: 1px solid #ccc;
    display: block;
    min-width: 200px;
    padding: 2px 5px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    display: inline-block;
    border-radius: 5px;
    transition: all 0.5s;
}

.tree .entry label:hover,
.tree .entry label:hover + .branch .entry label {
    background: #e6e6e6;
    color: #000;
    border-color: #a6a6a6;
}

.tree .entry label:hover + .branch .entry::after,
.tree .entry label:hover + .branch .entry::before,
.tree .entry label:hover + .branch::before,
.tree .entry label:hover + .branch .branch::before {
    border-color: #a6a6a6;
}

.tooltip-presmall.tooltip:before {
    font-size: 0.9em;
    max-width: 20em;
    white-space: pre-wrap;
}
